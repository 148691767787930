import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
          title
          defaultSEO: openGraph {
            title
            description
            image {
              file {
                asset {
                  fixed(width: 1080) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const seoDescription = description || site.defaultSEO.description
  const seoTitle = title || site.title || "Seagulls"
  const seoImage = image || site.defaultSEO.image.file.asset.fixed.src || ""

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seoTitle}
      titleTemplate={`Seagulls | ${seoTitle}`}
      meta={[
        {
          name: `description`,
          content: seoDescription,
        },
        {
          property: `og:title`,
          content: seoTitle,
        },
        {
          property: `og:description`,
          content: seoDescription,
        },
        {
          property: `og:image`,
          content: seoImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "@eytyy",
        },
        {
          name: `twitter:title`,
          content: seoTitle,
        },
        {
          name: `twitter:description`,
          content: seoDescription,
        },
      ]}
    >
      <meta name="facebook-domain-verification" content="4nitnxg2z7vqmagz4y5ds9jmtxt15n" />
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-BS7G02HWN2"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-BS7G02HWN2');`}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  title: "Seagulls",
  description: `With a well-integrated range of media platforms across Digital Out of Home,  Out of Home, Malls, and Broadcast, we help messages resonate with the people who matter.`,
  image: {},
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
}

export default SEO
